import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { splitDate } from '../../../helper/dateFormat';
import { selectLastDays } from '../../../helper/selectLastDays';
import { getCompanyStructure } from '../../../redux/actions/structure';
import { getTopicFilteredEvolution } from '../../../redux/actions/topics';
import LineChart from '../../dashboard/LineChart/LineChart';
import Grade from '../../global/Grade/Grade';
import Button from '../../global/form/Button/Button';
import Select from '../../global/form/Select/Select';
import './InvestmentResume.scss';

const InvestmentResume = ({ investment, investmentDetails, setShowModal, edit, investmentTasks }) => {
  const dispatch = useDispatch();
  const structure = useSelector((state) => state?.structure?.data);
  const [gradeVariation, setGradeVariation] = useState([]);
  // SELECTS 

  const getChartData = (evoluations) => {
    const monthNames = ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"];
    let chartData = evoluations || [];

    if (chartData.length === 0) {
      console.warn("Nenhum dado de evolução encontrado.");
      return [];
    }

    const groupedData = chartData.reduce((acc, item) => {
      const yearMonthKey = `${new Date(item.date).getFullYear()}-${new Date(item.date).getMonth() + 1}`;

      if (!acc[yearMonthKey]) {
        acc[yearMonthKey] = { ...item };
      } else {
        acc[yearMonthKey] = {
          ...acc[yearMonthKey],
          average: Math.max(acc[yearMonthKey].average, item.average),
          max: Math.max(acc[yearMonthKey].max, item.max),
          min: Math.min(acc[yearMonthKey].min, item.min),
        };
      }
      return acc;
    }, {});

    return Object.values(groupedData).map(item => ({
      ...item,
      monthName: monthNames[new Date(item.date).getMonth()]
    }));
  };

  const [selectedUnit, setSelectedUnit] = useState(null);
  const [roles, setRoles] = useState([]);

  const handleSelectChange = (event) => {
    const selectedValue = event;
    setSelectedUnit(selectedValue);

    const selectedUnitData = structure.find(unit => unit.name === selectedValue);
    if (selectedUnitData) {
      setRoles(selectedUnitData.roles);
    } else {
      setRoles([]);
    }
  };

  const [selectedRole, setSelectedRole] = useState('');
  const handleRoleChange = (event) => {
    setSelectedRole(event);
  };

  const [period, setPeriod] = useState("últimos 30 dias");
  const getFromDate = useCallback(() => {
    const selectPeriod = selectLastDays(period)
    let today = new Date();
    today = today.setDate(today.getDate() - selectPeriod);
    let date = new Date(today);
    return splitDate(date.toISOString());
  }, [period])

  useEffect(() => {
    if (investment) {
      const payloadFetch = {
        from: getFromDate(),
        role: selectedRole,
      }

      if (selectedUnit) { payloadFetch.unit = selectedUnit }

      dispatch(getTopicFilteredEvolution(investment.id, payloadFetch)).then((data) => {
        setGradeVariation(data)
      })
    }

    dispatch(getCompanyStructure());
  }, [investment, dispatch, period, selectedRole, selectedUnit])

  return (
    <div className='InvestmentResume'>
      <div className="select-container">
        <Select
          defaultOption="Selecione uma área"
          updateState={handleSelectChange}
          options={Array.isArray(structure) && structure?.map(unit => unit.name)}
          value={selectedUnit || 'Selecione uma unidade'}
        />
        <Select
          updateState={handleRoleChange}
          options={roles.length > 0 ? roles : ["Selecione uma área para liberar os níveis"]}
          displayError={false}
          value={selectedRole || ''}
          defaultOption="Selecionar nível"
        />
        <Select
          updateState={setPeriod}
          value={period}
          options={["últimos 30 dias", "últimos 60 dias", "últimos 90 dias"]}
          displayError={false}
        />
      </div>
      <main className="resume-tab-content">
        <div className="box">
          <p className="title-chart">Evolução de <span>{investment?.name}</span></p>
          <div className="chart">
            <div className="line-chart">
              <LineChart data={getChartData(Array.isArray(gradeVariation) ? gradeVariation : investmentDetails?.progression)} />
            </div>
          </div>
        </div>
        <div className="description">
          <div className="average-grade">
            <p className='title-average-grade'>Média <br /> da avaliação</p>
            <Grade value={investment?.tracking?.average} variation={investment?.tracking?.variation} />
          </div>
          <p className="investing-community">
            Na comunidade, <br /> este investimento está em {investment?.tracking?.variation > 0 ? "alta" : "baixa"}.
          </p>
          <p className="investing-description">
            Isso significa que o valor médio {investment?.tracking?.variation > 0 ? "subiu" : "desceu"}.
            Ou seja, mais pessoas indicaram que possuem de
            {investment?.tracking?.variation > 0
              ? " médio a alto"
              : " baixo a médio"}{" "}
            grau de proficiência nessa competência.
          </p>
          {investmentTasks && <div className="details">
            <span>{investmentTasks?.activeTasksCount || 0} aportes</span>
            <span>{investmentTasks?.affiliations || 0} Investidores</span>
          </div>}
          <Button className="button-new">
            Solicitar reavaliações  <span class="fa6-solid--arrow-right" />
          </Button>
          {/* <Button>Solicitar reavaliações</Button> */}
          {edit && <p onClick={() => setShowModal(true)} className='edit-investment' role='button'>Editar competência</p>}

        </div>

      </main>
    </div>
  )
}

export default InvestmentResume
