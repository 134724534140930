import React, { useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import InviteModal from '../../dashboard/InviteModal/InviteModal';
import InviteModalCollaborators from '../../dashboard/InviteModalCollaborators/InviteModalCollaborators';
import "./AccessHeader.scss";

const AccessHeader = ({ setUploadVisible, uploadVisible, active, isRootManager }) => {
  const history = useHistory();
  const { type } = useParams();
  const [isModalOpenModalManagers, setIsOpenModalManagers] = useState(false);
  const [isModalOpenCollaborators, setModalOpenCollaborators] = useState(false);
  const renderButtons = () => {
    if (!active) {
      return (
        <>
          <button
            className={
              type === "gestores" ? "active" : isRootManager?.level !== "MANAGER" ? "" : "disabled"
            }
            onClick={() => isRootManager && history.push("gestores")}
          >
            Gestores
            <div class="tooltip" role="tooltip">
              <span class="tooltiptext">
                Você não é um super-gestor. O que significa isso? Que somente
                super gestores tem acesso a gerenciar outros gestores
              </span>
            </div>
          </button>
          <button
            className={type === "colaboradores" ? "active" : ""}
            onClick={() => history.push("colaboradores")}
          >
            Colaboradores
          </button>
          {/* <button
            className={
              type === "convites-gestores" ? "active" : isRootManager?.level !== "MANAGER" ? "" : "disabled"
            }
            onClick={() => history.push("convites-gestores")}
          >
            Convite (Gestores)
          </button>
          <button
            className={type === "convites-colaboradores" ? "active" : ""}
            onClick={() => history.push("convites-colaboradores")}
          >
            Convite (Colaboradores)
          </button> */}
        </>
      );
    }
  }

  return (
    <div className="AccessHeader">
      <div className="container-header">
        <div className="info-text">
          <h1>Acessos</h1>
          <p>Aqui, você pode gerenciar os usuários e permissões da plataforma.</p>
        </div>
        <div className="container-buttons">
          <button onClick={() => setIsOpenModalManagers(true)}>
            CONVIDAR GESTORES <span className="fa6-solid--arrow-right" />
          </button>
          <button onClick={() => setModalOpenCollaborators(true)}>
            CONVIDAR COLABORADORES <span className="fa6-solid--arrow-right" />
          </button>
        </div>
      </div>
      {/* <div className="actions">
        {uploadVisible && (
          <Breadcrumb
            crumbs={["acessos", "colaboradores", "convite por lote"]}
          />
        )}
        {!uploadVisible && (type === 'gestores' || type === 'colaboradores') &&
          <div className="invite-buttons">
            <Button
              onClick={() => {
                history.push(`/${type}/convite`);
              }}
              invertColors
            >
              enviar convite por e-mail
            </Button>
            <Button onClick={() => setIsOpenModalManagers(true)}>Gestor</Button>
            <Button onClick={() => setModalOpenCollaborators(true)}>Colaboradores</Button>
            {type === "colaboradores" && (
              <React.Fragment>
                <Button onClick={() => setUploadVisible(true)} invertColors>
                  enviar convite por lote
                </Button>
              </React.Fragment>
            )}
          </div>
        }
      </div> */}
      <div className="navigation">{renderButtons()}</div>
      {isModalOpenModalManagers && <InviteModal onClose={() => setIsOpenModalManagers(false)} />}
      {isModalOpenCollaborators && <InviteModalCollaborators onClose={() => setModalOpenCollaborators(false)} />}
    </div>
  );
};

export default AccessHeader;
