import { getItemFromStorage, isAtLocalStorage, saveItemInStorage } from "../../helper/storage";
import { apiRefreshLogin } from "./login";
import {isTokenValid} from "../../helper/tokenValidation";

export const baseUrl = process.env.REACT_APP_E2W_API;

export const buildHeaders = (authorization = "") => {
  const headers = {
    "Content-Type": "application/json",
    "Accept": "application/json"
  }

  const auth = authorization || getItemFromStorage("TOKEN");

  if (auth) {
    headers["Authorization"] = `Bearer ${auth}`;
  }

  return headers;
}

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getResponseOrThrow = async response => {
  if (response.ok) {
    return isJsonString() ? response : response.json();
  } else {
    throw isJsonString() ? await response : response.json();
  }
};

export const urlFilters = (url, filters) => {
  if (filters === undefined) {
    return url;
  }

  const filtersKeys = Object.keys(filters);

  filtersKeys.forEach(key => {
    const keyValue = filters[key];
    if (keyValue !== '' && keyValue !== " ") {
      const symbol = url.includes('?') ? '&' : '?';
      url += `${symbol}${key}=${keyValue}`
    }
  });

  return url;
}

export const authorizedFetch = async (url, init) => {
  if( !isTokenValid() ) {
    const refreshToken = getItemFromStorage("REFRESH_TOKEN");
    try {
      const response = await apiRefreshLogin(refreshToken);
      const json = await getResponseOrThrow(response);
      saveItemInStorage("REFRESH_TOKEN", json.refreshToken, isAtLocalStorage("REFRESH_TOKEN"));
      saveItemInStorage("TOKEN", json.token, isAtLocalStorage("TOKEN"));
      return await fetch(url, init);
    } catch {
      return await Promise.reject();
    }
  } else {
    return fetch(url, init);
  }
};
