import React, { useEffect } from "react";
import "./PopUpCheck.scss";

const PopUpCheck = ({ children, buttonAction, autoClose, active }) => {

  useEffect(() => {
    const timer = setTimeout(() => {
      buttonAction()
    }, 2000);

    return () => clearTimeout(timer);
  }, [active]);

  return (
    <div className={`PopUpCheck ${active ? "active" : ""}`}>
      <div className="pop-up">
        <div className="check">
          <span class="game-icons--check-mark" />
        </div>
        <div className="container-pop-up">
          {children}
        </div>
      </div>
    </div>
  );
};

export default PopUpCheck;
