import Chart from "chart.js";
import "chartjs-plugin-datalabels";
import React, { useEffect, useRef, useState } from "react";
import { getChartData } from "./chartDatahelper";

const LineChart = ({ data }) => {
  const [chartStyles, setChartStyles] = useState(null);
  const [chartData, setChartData] = useState(null);
  const chartRef = useRef(null);
  let myChart;

  useEffect(() => {
    if (chartRef.current && chartStyles) {
      myChart = new Chart(chartRef.current, chartStyles);
    }
    return () => {
      myChart && myChart.destroy();
    };
  }, [chartStyles]);

  useEffect(() => {
    setChartData(getChartData(data));
  }, [data]);

  useEffect(() => {
    if (chartData) {
      setChartStyles({
        type: "line",
        data: {
          labels: chartData.labels,
          datasets: [
            {
              label: "Valor médio",
              data: chartData.average,
              backgroundColor: "transparent",
              borderColor: "#FFFFFF",
              borderWidth: 3,
              hoverRadius: 2,
              lineTension: 0,
              pointBackgroundColor: "#000338",
              pointBorderColor: "#FFFFFF",
              pointBorderWidth: 2,
              pointHoverBorderWidth: 7,
              pointRadius: 6,
              spanGaps: true
            },
            {
              label: "Maior valor",
              data: chartData.max,
              backgroundColor: "transparent",
              borderColor: "#00ff00",
              borderDash: [4],
              borderWidth: 2,
              hoverRadius: 2,
              lineTension: 0,
              pointBackgroundColor: "#000338",
              pointBorderColor: "#00ff00",
              pointBorderWidth: 3,
              pointHoverBorderWidth: 7,
              pointRadius: 6,
              spanGaps: true
            },
            {
              label: "Menor valor",
              data: chartData.min,
              backgroundColor: "transparent",
              borderColor: "red",
              borderDash: [4],
              borderWidth: 2,
              hoverRadius: 2,
              lineTension: 0,
              pointBackgroundColor: "#000338",
              pointBorderColor: "red",
              pointBorderWidth: 3,
              pointHoverBorderWidth: 7,
              pointRadius: 6,
              spanGaps: true
            }
          ]
        },
        options: {
          title: {
            display: false,
            text: 'VALORES CONSOLIDADOS MENSAIS',
            fontColor: "#8081a8",
            fontSize: 16,
            fontFamily: "Roboto-Regular",
          },
          plugins: {
            datalabels: {
              display: (context) => {
                const label = context.dataset.label;
                return label === "Maior valor" || label === "Menor valor" || label === "Valor médio";
              },
              align: 'top',
              color: (context) => {
                const label = context.dataset.label;
                if (label === "Maior valor") return '#00ff00';
                if (label === "Menor valor") return 'red';
                return '#FFFFFF';
              },
              font: {
                size: 17,
                weight: 'bold',
              },
              formatter: (value) => parseFloat(value.toFixed(2)),
            }
          },
          legend: {
            display: true,
            position: "bottom",
            align: 'start',
            labels: {
              usePointStyle: true,
              fontColor: "#FFFFFF",
              fontSize: 16,
              fontFamily: "Roboto",
              boxWidth: 10,
              boxHeight: 9,
              padding: 40,
              generateLabels: (chart) => {
                const original = Chart.defaults.global.legend.labels.generateLabels;
                const labels = original(chart);

                labels.forEach((label) => {
                  label.lineWidth = 3;
                  label.fontSize = 16;
                  label.color = "#FFFFFF";
                  label.pointStyle = 'circle'; // Define o estilo do ponto como círculo
                  label.fillStyle = "transparent"; // Preenchimento transparente para os boxes
                });

                return labels;
              }
            }
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  color: "transparent",
                  display: false,
                  drawBorder: false, // Remover linha de borda inferior
                  drawTicks: false,
                },
                ticks: {
                  autoSkip: true,
                  maxRotation: 90,
                  minRotation: 0,
                  padding: 15,
                  fontColor: "#FFFFFF",
                  fontSize: 16,
                }
              }
            ],
            yAxes: [
              {
                gridLines: {
                  color: "transparent",
                  display: false,
                  drawBorder: false, // Remover linha de borda esquerda
                  drawTicks: false,
                },
                ticks: {
                  fontColor: "transparent",
                  fontSize: 14,
                  padding: 32,
                  stepSize: 1,
                  suggestedMax: 12,
                  suggestedMin: 0,
                }
              }
            ]
          },
          maintainAspectRatio: false,
          responsive: true
        }
      });
    }
  }, [chartData]);

  return (
    <canvas ref={chartRef} style={{ borderRadius: '15px' }} /> // Estilo arredondado para o canvas
  );
};

export default LineChart;
