import { getItemFromStorage } from "../../helper/storage";
import { createInvestmentRequest, createInvestmentRequestFailed, createInvestmentRequestSucceeded, investmentDetailsInvestorRequest, investmentDetailsInvestorRequestFailed, investmentDetailsInvestorRequestSucceeded, investmentDetailsProgressionRequest, investmentDetailsProgressionRequestFailed, investmentDetailsProgressionRequestSucceeded, investmentDetailsTasksRequest, investmentDetailsTasksRequestFailed, investmentDetailsTasksRequestSucceeded, listInvestmentsRequest, listInvestmentsRequestFailed, listInvestmentsRequestSucceeded, listInvestmentsSummaryRequest, listInvestmentsSummaryRequestFailed, listInvestmentsSummaryRequestSucceeded, updateInvestmentRequest, updateInvestmentRequestFailed, updateInvestmentRequestSucceeded } from "../action-creators/investments";
import { apiCreateInvestmentRequest, apiInvestmentInvestors, apiInvestmentProgression, apiInvestmentsSummary, apiInvestmentTasks, apiListInvestmentsRequest, apiUpdateInvestmentRequest } from "../api/investments";

export const createInvestment = payload => async dispatch => {
  dispatch(createInvestmentRequest());
  const token = getItemFromStorage("TOKEN");
  const company_id = getItemFromStorage("COMPANY_ID");
  try {
    const json = await apiCreateInvestmentRequest(token, company_id, payload);
    dispatch(createInvestmentRequestSucceeded(json));
    return await Promise.resolve(json);
  } catch (response) {
    dispatch(createInvestmentRequestFailed(response));
    return await Promise.reject(response);
  }
};

export const listInvestments = (filters) => async dispatch => {
  dispatch(listInvestmentsRequest());
  const token = getItemFromStorage("TOKEN");
  const company_id = getItemFromStorage("COMPANY_ID");
  try {
    const json = await apiListInvestmentsRequest(token, company_id, filters);
    dispatch(listInvestmentsRequestSucceeded(json));
    return await Promise.resolve();
  } catch (response) {
    dispatch(listInvestmentsRequestFailed(response));
    return await Promise.reject(response);
  }
};

export const updateInvestment = (topicId, payload) => async dispatch => {
  dispatch(updateInvestmentRequest());
  const token = getItemFromStorage("TOKEN");
  const company_id = getItemFromStorage("COMPANY_ID");
  try {
    const json = await apiUpdateInvestmentRequest(token, company_id, topicId, payload);
    dispatch(updateInvestmentRequestSucceeded(json));
    return await Promise.resolve();
  } catch (response) {
    dispatch(updateInvestmentRequestFailed(response));
    return await Promise.reject(response);
  }
};

export const getInvestmentProgression = (topicId) => async dispatch => {
  dispatch(investmentDetailsProgressionRequest());
  const token = getItemFromStorage("TOKEN");
  const company_id = getItemFromStorage("COMPANY_ID");
  try {
    const json = await apiInvestmentProgression(token, company_id, topicId)
    dispatch(investmentDetailsProgressionRequestSucceeded(json));
    return await Promise.resolve(json);
  } catch (response) {
    dispatch(investmentDetailsProgressionRequestFailed(response));
    return Promise.reject(response);
  }
};

export const getInvestmentInvestors = (topicId) => async dispatch => {
  dispatch(investmentDetailsInvestorRequest());
  const token = getItemFromStorage("TOKEN");
  const company_id = getItemFromStorage("COMPANY_ID");
  try {
    const json = await apiInvestmentInvestors(token, company_id, topicId)
    dispatch(investmentDetailsInvestorRequestSucceeded(json));
    return await Promise.resolve(json);
  } catch (response) {
    dispatch(investmentDetailsInvestorRequestFailed(response));
    return Promise.reject(response);
  }
};

export const getInvestmentTasks = (filters) => async dispatch => {
  dispatch(investmentDetailsTasksRequest());
  const token = getItemFromStorage("TOKEN");
  const company_id = getItemFromStorage("COMPANY_ID");
  try {
    const json = await apiInvestmentTasks(token, company_id, filters)
    dispatch(investmentDetailsTasksRequestSucceeded(json));
    return await Promise.resolve(json);
  } catch (response) {
    dispatch(investmentDetailsTasksRequestFailed(response));
    return Promise.reject(response);
  }
};

export const listInvestmentsSummary = (filters) => async dispatch => {
  dispatch(listInvestmentsSummaryRequest());
  const token = getItemFromStorage("TOKEN");
  const company_id = getItemFromStorage("COMPANY_ID");
  try {
    const json = await apiInvestmentsSummary(token, company_id, filters);
    dispatch(listInvestmentsSummaryRequestSucceeded(json));
    return await Promise.resolve();
  } catch (response) {
    dispatch(listInvestmentsSummaryRequestFailed(response));
    return await Promise.reject(response);
  }
};
