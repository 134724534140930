import React from "react";
import Card from "../../global/Card/Card";
import Logo from "../../global/Logo/Logo";
import "./EntryGenericPage.scss";

const EntryGenericPage = ({ children }) => {
  return (
    <section className="EntryGenericPage">
      <div className="e2w-principles">
        <Logo />
        <h2>Conhecimento é riqueza. </h2>
        <p>Não diminui, não acaba.</p>
        <p>É o único investimento</p>
        <p>que sempre valoriza.</p>
      </div>
      <div className="container-children">
        <Card>{children}</Card>
      </div>
    </section>
  );
};

export default EntryGenericPage;
