import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import { ReactComponent as UploadIcon } from "../../../assets/images/upload.svg";
import { buildAndDownloadCsv } from "../../../helper/csvHelper";
import { validateFile } from "../../../helper/files";
import { getAccesses } from "../../../redux/actions/accesses";
import { addInvestors, getEmployees, updateEmployee } from "../../../redux/actions/employees";
import { inviteEmployee } from "../../../redux/actions/invites";
import { getCompanyStructure } from "../../../redux/actions/structure";
import DragAndDropUpload from "../../global/form/DragAndDropUpload/DragAndDropUpload";
import UploadButton from "../../global/form/UploadButton/UploadButton";
import Loading from "../../global/Loading/Loading";
import PopUpCheck from "../../global/PopUpCheck/PopUpCheck";
import SaveModal from "../../global/SaveModal/SaveModal";
import "./InviteModalCollaborators.scss";

const InviteModalCollaborators = ({ onClose, employee }) => {
  const dispatch = useDispatch();
  const [selectedRole, setSelectedRole] = useState("");
  const structure = useSelector((state) => state?.structure);
  const employees = useSelector(state => state.employees);
  const [units, setUnits] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedArea, setSelectedArea] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [popUpActive, setPopUpActive] = useState(false);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [initialInfo, setInitialInfo] = useState({ error: false });

  const [isModalOpenRole, setIsModalOpenRole] = useState(false);
  const [isModalOpenUnit, setIsModalOpenUnit] = useState(false);

  const handleAddUnit = (newArea) => {
    setUnits([...units, newArea]);
    setSelectedArea(newArea);
  };

  const handleAddRole = (newArea) => {
    setRoles([...roles, newArea]);
    setSelectedRole(newArea);
  };

  // UPLOAD CSV
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const handleFileUpload = (e) => {
    const file = (e.target && e.target.files[0]) || e;

    if (validateFile(file)) {
      setError(false)
      setFile(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    if (employee) {
      setEmail(employee?.person?.email);
      setSelectedArea(employee?.unit);
      setSelectedRole(employee?.role);
    }
  }, [employee]);

  useEffect(() => {
    dispatch(getCompanyStructure())
    dispatch(getAccesses());
  }, [dispatch]);

  useEffect(() => {
    if (selectedArea && Array.isArray(structure?.data)) {
      if (!employee) setSelectedRole("");
      const roles = structure?.data
        .map((unit) => (unit.name === selectedArea ? unit.roles : null))
        .filter(Boolean)
        .map((role) => role.map((item) => (item)))[0];
      setRoles(roles ?? []);
    }
  }, [selectedArea, structure]);


  useEffect(() => {
    if (Array.isArray(structure?.data)) {
      setUnits(structure?.data?.map((unit) => (unit.name)));
    }
  }, [structure?.data]);

  const handleChangeUnit = (event) => {
    const value = event.target.value;
    setSelectedArea(value);
    if (value) {
      setErrors((prev) => ({ ...prev, selectedArea: null }));
    }
  };

  const handleChangeRole = (event) => {
    const value = event.target.value;
    setSelectedRole(value);
    if (value) {
      setErrors((prev) => ({ ...prev, selectedRole: null }));
    }
  };

  const handleEmployeesCsvSubmission = () => {
    setIsLoading(true);
    const payload = {
      file
    };
    dispatch(addInvestors(payload))
      .then(() => {
        setPopUpActive(true);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }

  const handleInviteEmployee = () => {
    setIsLoading(true);

    const payload = {
      email,
      unit: selectedArea,
      role: selectedRole,
    };

    dispatch(inviteEmployee(payload)).then(() => {
      setIsLoading(false);
      dispatch(getEmployees());
      setPopUpActive(true);
    }).catch(() => setIsLoading(false));
  };

  const handleEditEmployee = () => {
    setIsLoading(true);
    if (selectedRole === employee?.role && selectedArea === employee?.unit) {
      setInitialInfo({ error: true });
      setPopUpActive(true);
      setIsLoading(false);
      return;
    }

    const payload = {
      unit: selectedArea,
      role: selectedRole,
    };
    dispatch(updateEmployee(payload, employee?.id)).then(() => {
      setIsLoading(false);
      dispatch(getEmployees());
      setPopUpActive(true);
    }).catch(() => setIsLoading(false));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (employee) {
      handleEditEmployee();
    } else {
      if (file) {
        handleEmployeesCsvSubmission();
      } else {
        const newErrors = {};
        if (!email) newErrors.email = "E-mail é obrigatório.";
        if (!selectedArea) newErrors.selectedArea = "Selecione uma área.";
        if (!selectedRole) newErrors.selectedRole = "Selecione um cargo.";

        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
        } else {
          handleInviteEmployee();
          setErrors({});
        }
      }
    }
  };

  return (
    <div className="InviteModalCollaborators">
      <div className="invite-modal">
        <Logo className="logo" />
        <button className="close-button" onClick={onClose}>
          FECHAR <span class="material-symbols--close" />
        </button>
        <div className="invite-modal-header">
          {!employee && <h2>Acessos <span className="solar--alt-arrow-right-line-duotone" />Convidar colaboradores</h2>}
          {employee && <h2>Acessos <span className="solar--alt-arrow-right-line-duotone" />Editar <span className="solar--alt-arrow-right-line-duotone" /> colaborador</h2>}
          <p>Aqui, você pode gerenciar os usuários e permissões da plataforma.</p>
        </div>
        <div className="container-box-invite">
          <form onSubmit={handleSubmit}>
            <div className={`invite-section`}>
              <h3>Para quem você quer enviar esse convite?</h3>
              <div className="inputs-box">
                <div>
                  <input
                    type="email"
                    placeholder="E-mail"
                    value={email}
                    disabled={employee}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      if (e.target.value) {
                        setErrors((prev) => ({ ...prev, email: null }));
                      }
                    }}
                  />
                  {errors.email && <span className="error">{errors.email}</span>}
                </div>
              </div>
              {!employee &&
                <>
                  <h3>Qual será a área e o cargo de acesso?</h3>
                  <div className="inputs-box">
                    <div className="custom-select-container next-button">
                      <div className="select-wrapper">
                        <select
                          className="custom-select"
                          value={selectedArea}
                          onChange={handleChangeUnit}
                        >
                          <option value="" disabled>
                            Selecione a área
                          </option>
                          {units?.map((area, index) => (
                            <option key={index} value={area}>
                              {area}
                            </option>
                          ))}
                        </select>
                        <span className="custom-icon">
                          <span className="weui--arrow-outlined" />
                        </span>
                      </div>
                      <button className="add-button" type="button" onClick={() => setIsModalOpenUnit(true)}>
                        +
                      </button>
                      {errors?.selectedArea && <span className="error">{errors.selectedArea}</span>}
                    </div>
                    <div className="custom-select-container next-button">
                      <div className="select-wrapper">
                        <select
                          className="custom-select"
                          value={selectedRole}
                          onChange={handleChangeRole}
                        >
                          <option value="" disabled>
                            Selecione o cargo
                          </option>
                          {roles?.map((role, index) => (
                            <option key={index} value={role}>
                              {role}
                            </option>
                          ))}
                        </select>
                        <span className="custom-icon">
                          <span className="weui--arrow-outlined" />
                        </span>
                      </div>
                      <button className="add-button" type="button" onClick={() => setIsModalOpenRole(true)}>
                        +
                      </button>
                      {errors?.selectedRole && <span className="error">{errors.selectedRole}</span>}
                    </div>
                  </div>
                </>}
            </div>
            <div className={`role-section`}>

              {!employee &&
                <>
                  <h3>Se preferir, adicione o arquivo CSV no padrão E2W com a lista de colaboradores.</h3>
                  <p className="download-csv">
                    <span onClick={() => buildAndDownloadCsv(
                      "nome;email;cargo;departamento\nJoão;joao@exemplo.com;desenvolvedor;TI"
                    )}>Clique aqui</span>
                    {" "} para baixar o padrão de CSV do E2W.
                  </p>
                  <p className="error-message">{error && "Ops! Esse não parece ser um arquivo .csv"}</p>
                  <DragAndDropUpload>
                    <UploadIcon />
                    {!file?.name ? <p className="describe-drag">arraste um arquivo csv para a tela ou</p> : <p>{file.name}</p>}
                    <UploadButton handleFileUpload={handleFileUpload} accept=".csv" />
                  </DragAndDropUpload>
                  <p className="info-describe">
                    Todos nessa lista receberão um convite via e-mail <br />para participar da comunidade.
                  </p>
                </>}
              {employee &&
                <>
                  <h3>Qual será a área e o cargo de acesso?</h3>
                  <div className="inputs-box">
                    <div className="custom-select-container next-button">
                      <div className="select-wrapper">
                        <select
                          className="custom-select"
                          value={selectedArea}
                          onChange={handleChangeUnit}
                        >
                          <option value="" disabled>
                            Selecione a área
                          </option>
                          {units?.map((area, index) => (
                            <option key={index} value={area}>
                              {area}
                            </option>
                          ))}
                        </select>
                        <span className="custom-icon">
                          <span className="weui--arrow-outlined" />
                        </span>
                      </div>
                      <button className="add-button" type="button" onClick={() => setIsModalOpenUnit(true)}>
                        +
                      </button>
                      {errors?.selectedArea && <span className="error">{errors.selectedArea}</span>}
                    </div>
                    <div className="custom-select-container next-button">
                      <div className="select-wrapper">
                        <select
                          className="custom-select"
                          value={selectedRole}
                          onChange={handleChangeRole}
                        >
                          <option value="" disabled>
                            Selecione o cargo
                          </option>
                          {roles?.map((role, index) => (
                            <option key={index} value={role}>
                              {role}
                            </option>
                          ))}
                        </select>
                        <span className="custom-icon">
                          <span className="weui--arrow-outlined" />
                        </span>
                      </div>
                      <button className="add-button" type="button" onClick={() => setIsModalOpenRole(true)}>
                        +
                      </button>
                      {errors?.selectedRole && <span className="error">{errors.selectedRole}</span>}
                    </div>
                  </div>
                </>
              }

              <button type="submit" className="submit-button">
                {employee ? 'SALVAR' : 'ENVIAR CONVITE'} <span className="fa6-solid--arrow-right" />
              </button>
            </div>
          </form>
        </div>
      </div>
      <Loading visible={(structure.isFetching || employees?.isFetching) || isLoading} message="Carregando dados" />
      {popUpActive && <PopUpCheck
        buttonAction={() => {
          setPopUpActive(false)
          onClose();
        }}
        active={popUpActive}
        children={<p>{initialInfo.error ? 'Você não editou nenhuma informação!' : employee ? 'EDITADO COM SUCESSO!' : 'CONVITE ENVIADO COM SUCESSO!'}</p>}
      />}
      <SaveModal
        isOpen={isModalOpenUnit}
        onClose={() => setIsModalOpenUnit(false)}
        onSave={handleAddUnit}
      />
      <SaveModal
        isOpen={isModalOpenRole}
        onClose={() => setIsModalOpenRole(false)}
        onSave={handleAddRole}
        title="Adicionar novo cargo"
      />
    </div>
  );
};

export default InviteModalCollaborators;
