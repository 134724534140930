import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { onEnterPressed } from "../../../helper/eventHelper";
import microsoft from '../../../assets/images/microsoft-icon.svg';
import { useEmailField, usePasswordField, useTextField } from "../../../hooks/inputHooks";
import { acceptInvite, getManagerInvite } from "../../../redux/actions/invites";
import { login } from "../../../redux/actions/login";
import { signUp } from "../../../redux/actions/signup";
import Loading from "../../global/Loading/Loading";
import PopUpCompany from "../../global/PopUpCompany/PopUpCompany";
import Button from "../../global/form/Button/Button";
import Input from "../../global/form/Input/Input";
import "./SignUp.scss";

const SignUp = () => {
  const [name, setName, validName] = useTextField("");
  const [email, setEmail, validEmail] = useEmailField("");
  const [password, setPassword, validPassword] = usePasswordField("");
  const [
    passwordConfirmation,
    setPasswordConfirmation,
    validPasswordConfirmation
  ] = usePasswordField("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordEqualError, setPasswordEqualError] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const signup = useSelector(state => state.signup);
  const inviteInfo = useSelector(state => state.invites);
  const history = useHistory();
  const location = useLocation();
  const inviteId = location.search.split("code=")[1];

  useEffect(() => {
    if (inviteId) {
      setIsLoading(true)
      dispatch(getManagerInvite(inviteId))
        .then(() => setIsLoading(false))
    }
  }, [inviteId, dispatch])

  const handleSignUp = (e) => {
    e && e.preventDefault();
    if (!validName || !validEmail) {
      setDisplayError(true);
    } else if (passwordConfirmation !== password) {
      setPasswordEqualError(true)
    }
    else if (
      !validPassword ||
      !validPasswordConfirmation
    ) {
      setPasswordError(true);
    } else {
      const payload = {
        name,
        email,
        password
      }
      setIsLoading(true);
      dispatch(signUp(payload))
        .then(
          () => {
            dispatch(login({ email, password })).then(() => {
              if (inviteInfo.data !== [] && inviteInfo.data.id) {
                dispatch(
                  acceptInvite(inviteInfo.data.id, {
                    accept: true
                  })
                ).then(() => {
                  setIsLoading(false);
                  setPopUp(true);
                });
              } else {
                setIsLoading(false);
                history.push("/selecionar-empresa");
              }
            });

          }
        ).catch(() => {
          setFeedback(true);
          setIsLoading(false);
          setTimeout(() => {
            setFeedback(false);
          }, 5000);
        });
    }
  }

  const handleMicrosoft = () => {
    const handleLoginMicrosoft = () => {
      const loginUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=4e243692-2927-48cc-af80-73458c25b6df&scope=user.read%20openid%20profile%20offline_access&redirect_uri=https%3A%2F%2Fe2w-gestor.homolog.mastertech.com.br%2Flogin&response_mode=fragment&response_type=code&prompt=consent`;
      window.open(loginUrl, '_blank');
    };

    handleLoginMicrosoft();
  }

  const handlePopUpClose = () => {
    setPopUp(false);
    history.push("/selecionar-empresa");
  }

  return (
    <section className="SignUp">
      <article>
        <h2>Vamos começar.</h2>
        <p>Por favor, insira os seus dados para criar a sua conta.</p>
      </article>
      {inviteId && (
        <div className="welcome-text">
          {inviteInfo.isFetching && <h3>Carregando...</h3>}
          {!inviteInfo.isFetching && !inviteInfo.data.company && <h3>Ops! Parece que você acessou o link de um convite expirado ou inválido.</h3>}
          {inviteInfo.data.company && <>
            <img src={inviteInfo.data.company && inviteInfo.data.company.icon} />
            <div>
              <h3>Olá.</h3>
              <h3>Realize seu login </h3>
              <h3>para se tornar</h3>
              <h3>um administrador</h3>
              <h3>da {inviteInfo.data.company && inviteInfo.data.company.name}</h3>
            </div>
          </>}
        </div>
      )
      }
      <form className="inputs" onSubmit={handleSignUp}>
        <p className={`feedback ${feedback ? "visible" : null}`}>
          Já existe um usuário com esse email no sistema, faça o login
        </p>
        <Input
          displayError={displayError}
          displayErrorMessage="Email inválido"
          label="E-mail"
          type="email"
          updateState={setEmail}
          value={email}
          valid={validEmail}
          onKeyUp={event => onEnterPressed(event, handleSignUp)}
        />
        <Input
          displayError={displayError}
          displayErrorMessage="Nome inválido"
          label="Nome"
          updateState={setName}
          value={name}
          valid={validName}
        />
        <Input
          displayError={passwordError}
          displayErrorMessage="Sua senha deve conter 6 caracteres dentre eles letras, números e um caractere especial, além de estarem iguais!"
          label="Senha"
          type="password"
          updateState={setPassword}
          value={password}
          valid={!passwordError}
          onKeyUp={event => {
            setPasswordError(false)
            onEnterPressed(event, handleSignUp)
          }}
        />
        <Input
          displayError={passwordEqualError}
          displayErrorMessage="Senha e confirmação diferentes"
          label="Confirmar senha"
          type="password"
          updateState={setPasswordConfirmation}
          value={passwordConfirmation}
          valid={!passwordEqualError}
          onKeyUp={event => {
            setPasswordEqualError(false)
            onEnterPressed(event, handleSignUp)
          }}
        />
        <button
          type={"button"}
          className="terms-of-use-button"
          onClick={() => window.open('/termos-de-uso', '_blank')}
        >
          Ao se cadastrar você concorda com os <span>termos de uso</span>
        </button>
        <button
          type={"button"}
          className="already-registered-button"
          onClick={() => inviteId ? history.push("/login?code=" + inviteId) : history.push("/login")}
        >
          Já é um membro? Clique aqui para fazer login
        </button>
        <div className="buttons">
          <Button type={"submit"} bottom onClick={handleSignUp}>
            cadastrar-se
          </Button>
          <div>
            <div onClick={e => handleMicrosoft(e)} role="button" className="microsoft-button">
              <img src={microsoft} alt="microsoft" />
              <p className="text-microsoft">entrar com microsoft</p>
            </div>
            {/* <GoogleSignIn label="cadastrar com google" />
            <LinkedinSignIn text="cadastrar com linkedIn" /> */}
          </div>
        </div>
      </form>
      <PopUpCompany
        company={inviteInfo.data.company && inviteInfo.data.company.name} active={popUp}
        companyImage={inviteInfo.data.company && inviteInfo.data.company.icon}
        closeCompany={() => {
          handlePopUpClose()
        }} />
      <Loading visible={signup.isFetching || isLoading} />
    </section>
  );
};

export default SignUp;
