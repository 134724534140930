import { baseUrl, buildHeaders, getResponseOrThrow } from "./common";

export const apiCompanyRequest = (payload, token) => {
  let body = new FormData();
  for(let key in payload) {
    body.append(key, payload[key]);
  }

  return fetch(`${baseUrl}/company`,
    {
      method: "POST",
      headers: {
        "Authorization" : `Bearer ${token}`
      },
      body: body
    })
    .then(getResponseOrThrow)
    .catch((error) => Promise.reject(error));
};

export const apiCompanyMetricsEngagementRequest = (token, company_id) => {
  return fetch(`${baseUrl}/company/${company_id}/metrics/engagement/month?months=12`, {
    method: "GET",
    headers: buildHeaders(token)
  })
    .then(getResponseOrThrow)
    .catch(error => Promise.reject(error));
}
