import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPersonExecutions } from "../../../redux/actions/executions";
import Table from "../../global/Table/Table";
import "./ModalContributions.scss";
import ModalContributionsTableRow from "./ModalContributionsTableRow/ModalContributionsTableRow";
import { translateAcquistionMethodEnum, translateContentFormatEnum, translateContentTypeEnum, translateExecutionFormatEnum, translateStatusEnum } from '../../../helper/tasksEnum';
import TopicSymbol from '../../global/TopicSymbol/TopicSymbol';
import { localizeDate, shortYearDateFormat } from '../../../helper/dateHelper';

const ModalContributions = ({ investorId }) => {
  const dispatch = useDispatch();
  const myExecutionsData = useSelector((state) => state?.executions?.data?.items);

  useEffect(() => {
    dispatch(getPersonExecutions(investorId));
  }, [dispatch, investorId])

  return (
    <div className="ModalContributions">
      <div className="contributions-search">
        <div className="contributions-count">
          <h1>Aportes</h1>
          <p>{myExecutionsData && (myExecutionsData.length + " " + (myExecutionsData.length > 1 ? "Aportes" : "Aporte"))}</p>
        </div>
      </div>
      {/* <Table
        className="ModalContributionsTableRow"
        headers={[
          "Investimento",
          "Nome do Aporte",
          "Formato",
          "Meio",
          "Modalidade",
          "Custo",
          "Status",
          "Prazo",
        ]}
      >
        {myExecutionsData?.map((execution, key) => (
          <ModalContributionsTableRow execution={execution} key={key} />
        ))}
      </Table> */}

      <Table
        className="investors-table"
        headers={[
          "Investimento",
          "Nome do Aporte",
          "Formato",
          "Meio",
          "Modalidade",
          "Custo",
          "Status",
          "Prazo",
        ]}
      >
        {myExecutionsData ? myExecutionsData?.map((execution) => (
          <tr key={execution?.id}>
            <td>
              <TopicSymbol symbol={execution?.studyTask?.topic?.symbol} />
            </td>
            <td>
              <p>{execution?.studyTask?.title}</p>
            </td>
            <td>{translateContentTypeEnum(execution?.studyTask?.contentType)}</td>
            <td>
              {translateContentFormatEnum(execution?.studyTask?.contentFormat)}
            </td>
            <td>
              {translateExecutionFormatEnum(execution?.studyTask?.taskFormat)}
            </td>
            <td>
              {translateAcquistionMethodEnum(
                execution?.studyTask?.contentAcquisition
              )}
            </td>
            <td>
              <p>{translateStatusEnum(execution?.status)}</p>
            </td>
            <td>
              {execution?.lastDate
                ? shortYearDateFormat(localizeDate(execution?.lastDate), "/")
                : `${execution?.timeItTook} ${execution?.timeItTook > 1 ? "horas" : "hora"
                }`}
            </td>
          </tr>
        )) : <p style={{ textAlign: "center" }}>Nenhuma avaliação encontrada</p>}
      </Table>
    </div>
  );
};

export default ModalContributions;
