import React, { useState } from "react";
import "./SaveModal.scss";

const SaveModal = ({ isOpen, onClose, onSave, title }) => {
  const [newArea, setNewArea] = useState("");

  const handleSave = (e) => {
    e.preventDefault();
    if (newArea.trim()) {
      onSave(newArea);
      setNewArea("");
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="SaveModal">
      <div className="modal-container">
        <button className="close-button" onClick={onClose}>
          &times;
        </button>
        <p className="modal-title">{title ? title : "Digite o nome da nova área"}:</p>
        <input
          type="text"
          className="modal-input"
          value={newArea}
          onChange={(e) => setNewArea(e.target.value)}
          placeholder="Nova área"
        />
        <button className="save-button" onClick={handleSave}>
          SALVAR
        </button>
      </div>
    </div>
  );
};

export default SaveModal;
