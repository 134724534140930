import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../../assets/images/access-delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/access-edit.svg";
import { translateGestorLevel } from '../../../helper/dataHelper';
import { deleteManager, getManagers } from "../../../redux/actions/managers";
import InviteModal from '../../dashboard/InviteModal/InviteModal';
import DeletePopup from "../../global/DeletePopup/DeletePopup";
import "./ManagersTable.scss";

const ManagersTable = ({ data }) => {
  const [popUp, setPopUp] = useState(false);
  const [managerName, setManagerName] = useState("");
  const [managerId, setManagerId] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [id, setId] = useState(null);

  const openPopUp = (name, id) => {
    setManagerName(name);
    setManagerId(id);
    setPopUp(true);
  };

  const deleteEmployeeClick = () => {
    dispatch(deleteManager(managerId)).then(() => {
      setPopUp(false);
      dispatch(getManagers());
    });
  };

  return (
    <>
      <table className="ManagersTable">
        <thead>
          <tr>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Áreas</th>
            <th>Status</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {data.items && data.items.length > 0 ? (
            data.items.map((managerData) => (
              <tr>
                <td>{managerData && managerData.manager.name}</td>
                <td>{managerData && managerData.manager.email}</td>
                <td>
                  {managerData &&
                    managerData.units.map((department) =>
                      department === "*" ? `Todos` : `${department} `
                    )}
                </td>
                <td>
                  <div class="status">
                    <span class="dot"></span>
                    {managerData && translateGestorLevel(managerData.level)}
                  </div>
                </td>
                <td
                  onClick={() => {
                    setId(managerData);
                    setOpenEditModal(true);
                  }}
                  className="edit"
                >
                  <p>
                    <EditIcon /> editar
                  </p>
                </td>
                <td
                  onClick={() => {
                    openPopUp(managerData.manager.name, managerData.id);
                  }}
                  className='trash'
                >
                  <DeleteIcon />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td />
              <td colSpan={8}>
                Você não possui gestores cadastrados em sua base - para
                adicionar clique em enviar convite por email.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <DeletePopup
        name={managerName}
        active={popUp}
        deleteClick={deleteEmployeeClick}
        cancelClick={() => setPopUp(false)}
      />

      {openEditModal && <InviteModal onClose={() => setOpenEditModal(false)} manager={id} />}
    </>
  );
};

ManagersTable.propTypes = {
  data: PropTypes.object.isRequired
};

export default ManagersTable;
