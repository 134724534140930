import React from 'react';
import './PopUpCompany.scss';
import Button from '../form/Button/Button';

const PopUpCompany = ({ active, company, companyImage, closeCompany }) => {
  return (
    <div className={`PopUpCompany ${active ? "active" : ""}`}>
      <div className="container-popup">
        <img className='image-company' src={companyImage} alt={company} />
        <div>
          <p className='title'>Está tudo pronto!</p>
          <p className="description">
            {company} foi adicionar à lista de empresas <br/> nas quais você administra.
          </p>
        </div>
        <Button className="button-submit" onClick={closeCompany} >
            CONTINUAR  <span class="fa6-solid--arrow-right"/>
          </Button>
      </div>
    </div>

  );
};

export default PopUpCompany;
