import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { listInvestments } from "../../../redux/actions/investments";
import { getCommunityMetrics } from "../../../redux/actions/metrics";
import { getTrending } from "../../../redux/actions/trending";
import Card from "../../global/Card/Card";
import CommunityMetricsDistribution from "../../global/CommunityMetricsDistribution/CommunityMetricsDistribution";
import Button from "../../global/form/Button/Button";
import Grade from "../../global/Grade/Grade";
import Loading from "../../global/Loading/Loading";
import InvestmentDetails from "../../investments/InvestmentDetails/InvestmentDetails";
import "./Summary.scss";

const Summary = ({ setSelectedAbility }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const topics = useSelector(state => state.topics);
  const investments = useSelector(state => state.investments);
  const engagement = useSelector(state => state.engagement);
  const evaluations = useSelector(state => state.evaluations);
  const login = useSelector(state => state.login);
  const demo = useSelector(state => state.demo);
  const companyMetricsEngagement = useSelector((state) => state?.metrics);
  const communityMetrics = companyMetricsEngagement?.communityMetrics?.data;
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedContribution, setSeletedContribution] = useState(null);

  useEffect(() => {
    if (!demo.isDemoMode) {
      setSelectedAbility(topics.topicsList.data[0]?.topic.id)
    } else {
      setSelectedAbility(demo.data?.topics?.topicsList[0].id)
    }

    if (!openDetails) {
      setSeletedContribution(null);
    }
  }, [topics.topicsList.data, setSelectedAbility, demo.isDemoMode, demo.data.topics, openDetails]);

  useEffect(() => {
    dispatch(getTrending())
    dispatch(getCommunityMetrics());
    dispatch(listInvestments())
  }, []);
  console.log()


  return (
    <section className="Summary">
      <article className="welcome-text">
        <p className="subtitle">Olá, Gestor(a)!</p>
        <p>
          Há {demo.isDemoMode ? demo.data.topics.topicsList.length : investments?.data?.totalItems || 0} investimento{(demo.isDemoMode ? demo.data.topics.topicsList.length > 1 : investments?.data?.totalItems > 1) ? "s" : ""} em  desenvolvimento entre os investidores.
        </p>
      </article>
      <div className="container-infos">
        {/* <h3>teste</h3> */}
        <Card className='card-metrics' container title="Alocação de investimentos">
          <CommunityMetricsDistribution community={communityMetrics} skill />
        </Card>
        <div className="container-block">
          <div className="top-block">
            <Card className='card-high' title="Maior valor">
              <Button
                linkStyle
                onClick={() => {
                  setOpenDetails(true)
                  setSeletedContribution(communityMetrics?.topicWithHighestValue)
                }}
              >
                Ver Detalhes
              </Button>
              <article>
                <p>{communityMetrics?.topicWithHighestValue?.name}</p>
                <p className="grade">
                  <Grade value={communityMetrics?.topicWithHighestValue?.tracking?.average} variation={communityMetrics?.topicWithHighestValue?.tracking?.variation} />
                </p>
              </article>
            </Card>
            <Card className='card-high' title="Menor valor">
              <Button
                linkStyle
                onClick={() => {
                  setOpenDetails(true)
                  setSeletedContribution(communityMetrics?.topicWithLowestValue)
                }}
              >
                Ver Detalhes
              </Button>
              <article>
                <p>{communityMetrics?.topicWithLowestValue?.name}</p>
                <p className="grade">
                  <Grade value={communityMetrics?.topicWithLowestValue?.tracking?.average} variation={communityMetrics?.topicWithLowestValue?.tracking?.variation} />
                </p>
              </article>
            </Card>
          </div>
          <div className="bottom-block">
            <Card className='card-engagement' title="Engajamento">
              {/* <Button
                linkStyle
              >
                Ver Detalhes
              </Button> */}
              <article>
                <div className="active-investidores">
                  <span>{demo.isDemoMode ? demo.data?.engagement?.count : engagement.data?.count}</span>
                  <p>Investidores <br /> Ativos</p>
                </div>
                <div className="infos-engagement">
                  <p>{demo.isDemoMode ? demo.data?.engagement?.percentage : engagement.data?.percentage}% do total</p>
                  <p>{demo.isDemoMode ? demo.data?.engagement?.percentage : engagement.data.totalCount} investidores <br /> cadastrados</p>
                </div>
              </article>
            </Card>
            <Card className='card-info' title="Precisa de ajuda?">
              <Button
                linkStyle
                onClick={() => history.push("/contato")}
              >
                Ver
              </Button>
              <article>
                <p>Confira a nossa <br /> seção de suporte.</p>
              </article>
            </Card>
          </div>
        </div>
      </div>
      <Loading
        visible={
          demo.isDemoMode
            ? demo.isFetching
            : engagement.isFetching
        }
      />

      {openDetails && (
        <InvestmentDetails
          edit={false}
          investment={selectedContribution}
          handleClose={() => setOpenDetails(false)}
        />
      )}
    </section>
  );
}

Summary.propTypes = {
  selectedAbility: PropTypes.number.isRequired,
  setSelectedAbility: PropTypes.func.isRequired
};

export default Summary;
