import PropTypes from "prop-types";
import React from "react";
import Grade from "../../global/Grade/Grade";
import "./CompanyAbilitiesTable.scss";

const CompanyAbilitiesTable = ({ companyAbilities, selectedAbility, setSelectedAbility }) => {
  return (
    <table className="CompanyAbilitiesTable">
      <thead>
        <tr>
          <th>INVESTIMENTO</th>
          <th>MÉDIA EM TEMPO REAL</th>
        </tr>
      </thead>
      <tbody>
        {companyAbilities && companyAbilities.map((asset, key) => {
          return (
            <tr
              className={selectedAbility === asset.topic.id ? "active" : ""}
              key={key}
              onClick={() => { setSelectedAbility(asset.topic.id) }}
            >
              {/* <td><TopicSymbol symbol={asset.topic.symbol}/></td> */}
              <td>{asset.topic.name}</td>
     
              <td>
                <Grade value={asset.evaluation} variation={asset.variation} />
              </td>
              <td>
                <span class="fa6-solid--arrow-right" />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

CompanyAbilitiesTable.propTypes = {
  companyAbilities: PropTypes.array.isRequired
};

export default CompanyAbilitiesTable;
