export const CREATE_INVESTMENT = "CREATE_INVESTMENT";
export const CREATE_INVESTMENT_SUCCEEDED = "CREATE_INVESTMENT_SUCCEEDED";
export const CREATE_INVESTMENT_FAILED = "CREATE_INVESTMENT_FAILED";

export const LIST_INVESTMENTS = "LIST_INVESTMENTS";
export const LIST_INVESTMENTS_SUCCEEDED = "LIST_INVESTMENTS_SUCCEEDED";
export const LIST_INVESTMENTS_FAILED = "LIST_INVESTMENTS_FAILED";

export const UPDATE_INVESTMENT = "UPDATE_INVESTMENT";
export const UPDATE_INVESTMENT_SUCCEEDED = "UPDATE_INVESTMENT_SUCCEEDED";
export const UPDATE_INVESTMENT_FAILED = "UPDATE_INVESTMENT_FAILED";

export const INVESTMENT_DETAILS_PROGRESSION = "INVESTMENT_DETAILS_PROGRESSION";
export const INVESTMENT_DETAILS_PROGRESSION_SUCCEEDED = "INVESTMENT_DETAILS_PROGRESSION_SUCCEEDED";
export const INVESTMENT_DETAILS_PROGRESSION_FAILED = "INVESTMENT_DETAILS_PROGRESSION_FAILED";

export const INVESTMENT_DETAILS_INVESTORS = "INVESTMENT_DETAILS_INVESTORS";
export const INVESTMENT_DETAILS_INVESTORS_SUCCEEDED = "INVESTMENT_DETAILS_INVESTORS_SUCCEEDED";
export const INVESTMENT_DETAILS_INVESTORS_FAILED = "INVESTMENT_DETAILS_INVESTORS_FAILED";

export const INVESTMENT_DETAILS_TASKS = "INVESTMENT_DETAILS_TASKS";
export const INVESTMENT_DETAILS_TASKS_SUCCEEDED = "INVESTMENT_DETAILS_TASKS_SUCCEEDED";
export const INVESTMENT_DETAILS_TASKS_FAILED = "INVESTMENT_DETAILS_TASKS_FAILED";

export const LIST_INVESTMENTS_SUMMARY = "LIST_INVESTMENTS_SUMMARY";
export const LIST_INVESTMENTS_SUMMARY_SUCCEEDED = "LIST_INVESTMENTS_SUMMARY_SUCCEEDED";
export const LIST_INVESTMENTS_SUMMARY_FAILED = "LIST_INVESTMENTS_SUMMARY_FAILED";
