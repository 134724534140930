import { authorizedFetch, baseUrl, buildHeaders, getResponseOrThrow } from "./common";

export const apiEngagement = (token, company_id) => {
  return authorizedFetch(`${baseUrl}/company/${company_id}/metrics/engagement/month?months=12`,
    {
      method: "GET",
      headers: buildHeaders(token)
    }).then(getResponseOrThrow)
    .then((json)=> Promise.resolve(json))
    .catch((error) => Promise.reject(error));
};
