import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as DeleteIcon } from "../../../assets/images/access-delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/images/access-edit.svg";
import { deleteEmployee, getEmployees } from "../../../redux/actions/employees";
import InviteModalCollaborators from "../../dashboard/InviteModalCollaborators/InviteModalCollaborators";
import DeletePopup from "../../global/DeletePopup/DeletePopup";
import "./EmployeesTable.scss";

const EmployeesTable = ({ data }) => {
  const [popUp, setPopUp] = useState(false);
  const [employeeName, setEmployeeName] = useState("");
  const [employeeId, setEmployeeId] = useState(0);
  const dispatch = useDispatch();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [id, setId] = useState(null);

  const openPopUp = (name, id) => {
    setEmployeeName(name);
    setEmployeeId(id);
    setPopUp(true);
  };

  const deleteEmployeeClick = () => {
    dispatch(deleteEmployee(employeeId)).then(() => {
      setPopUp(false);
      dispatch(getEmployees());
    });
  };

  const toCamelCaseWithSymbols = (text) => {
    if (!text) return '';
    return text
      .toLowerCase()
      .replace(/(?:^|\s|\(|\[|\{)([a-zà-ú])/g, (match, p1) => match.toUpperCase().replace(p1, p1.toUpperCase()));
  };

  return (
    <>
      <table className="EmployeesTable">
        <thead>
          <tr>
            <th>Nome</th>
            {/* <th /> */}
            <th>E-mail</th>
            <th>Área</th>
            <th>Cargo</th>
            <th>Status</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {data?.items?.length > 0 ? (
            data.items.map((personData) => {
              return (
                <tr>
                  <td>{personData && personData.person.name}</td>
                  {/* <td></td> */}
                  <td>{personData && personData.person.email}</td>
                  <td className="unit">{personData && toCamelCaseWithSymbols(personData.unit)}</td>
                  <td>{personData && toCamelCaseWithSymbols(personData.role)}</td>
                  <td>
                    <div class="status">
                      <span class="dot"></span>
                      Convite aceito
                    </div>
                  </td>
                  <td
                    className="edit"
                  >
                    <p
                      onClick={() => {
                        setId(personData);
                        setOpenEditModal(true);
                      }}
                    >
                      <EditIcon /> editar
                    </p>
                  </td>
                  <td
                    onClick={() => {
                      openPopUp(personData.person.name, personData.id);
                    }}
                    className="trash"
                  >
                    <DeleteIcon />
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td />
              <td colSpan={8}>
                Você não possui colaboradores cadastrados em sua base - para
                adicionar clique em enviar convite por email.
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <DeletePopup
        name={employeeName}
        active={popUp}
        deleteClick={deleteEmployeeClick}
        cancelClick={() => {
          setPopUp(false);
        }}
      />
      {openEditModal && <InviteModalCollaborators employee={id} onClose={() => setOpenEditModal(false)} />}
    </>
  );
};

EmployeesTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default EmployeesTable;
