import React from 'react'
import './MiniLoading.scss'

const MiniLoading = ({ message }) => {
  return (
    <div className='MiniLoading'>
      <div>
        <div className={"container"}>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
        {message && <p>{message}</p>}
      </div>
    </div>
  )
}

export default MiniLoading
