import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { localizeDate, shortYearDateFormat } from "../../../helper/dateHelper";
import { topicPersonSummary } from '../../../redux/actions/topics';
import Grade from "../../global/Grade/Grade";
import Table from '../../global/Table/Table';
import TopicSymbol from "../../global/TopicSymbol/TopicSymbol";
import "./ModalInvestments.scss";

const ModalInvestments = ({ investorId }) => {
  const dispatch = useDispatch();
  const myTopicsData = useSelector((state) => state?.topics?.person?.data);

  useEffect(() => {
    dispatch(topicPersonSummary(investorId))
  }, [dispatch, investorId]);

  return (
    <div className="ModalInvestments">
      <div className="investments">
        <div className="investments-count">
          <h1>Investimentos</h1>
          <p>{myTopicsData?.items?.length} competências</p>
        </div>
        <Table
          className="investors-table"
          headers={[
            "Código",
            "Comunidade",
            "Investimento",
            "Investidor",
            "Aportes",
            "ÚLTIMA ATUALIZAÇÃO",
          ]}
        >
          {myTopicsData?.items ? myTopicsData?.items?.map((stock) => (
            <tr key={stock?.id}>
              <td>
                <TopicSymbol symbol={stock.topic.symbol} />
              </td>
              <td>
                <Grade
                  value={stock.topic?.tracking?.average || 0}
                  variation={stock.topic?.tracking?.variation || 0}
                />
              </td>
              <td>
                <p>{stock.topic.name}</p>
              </td>
              <td>
                <Grade
                  value={stock.lastValue}
                  variation={stock.lastValue < 7}
                  simplified
                />
              </td>
              <td>
                <p>{stock?.activeTasksCount}</p>
              </td>
              <td>
                <p>
                  {stock.lastDate &&
                    shortYearDateFormat(localizeDate(stock.lastDate), "/")}
                </p>
              </td>
            </tr>
          )) : <p style={{ textAlign: "center" }}>Nenhuma avaliação encontrada</p>}
        </Table>
      </div>
    </div>
  );
};

export default ModalInvestments;
