export const selectLastDays = (value) => {
  switch (value) {
    case "últimos 7 dias":
      return 7;

    case "últimos 15 dias":
      return 15;

    case "últimos 30 dias":
      return 30;

    case "últimos 60 dias":
      return 60;

    case "últimos 90 dias":
      return 90;

    default:
      return "";
  }
}
