import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isUserAuthenticated } from "../../../redux/actions/authentication";
import { getDemo } from "../../../redux/actions/demo";
import { getEngagement } from "../../../redux/actions/engagement";
import { getTasks } from "../../../redux/actions/tasks";
import { getTopicsProgressionByMonth } from "../../../redux/actions/topics";
import Loading from "../../global/Loading/Loading";
import AbilitiesAnalysis from "../AbilitiesAnalysis/AbilitiesAnalysis";
import Summary from "../Summary/Summary";
import "./Dashboard.scss";

const Dashboard = () => {
  const [selectedAbility, setSelectedAbility] = useState("");
  const demo = useSelector(state => state.demo);
  const topics = useSelector(state => state.topics);
  const trends = useSelector(state => state.trending);
  const tasks = useSelector(state => state.tasks);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!demo.isDemoMode) {
      !isUserAuthenticated() && history.push("/login");
    }
  }, [history, demo.isDemoMode]);

  useEffect(() => {
    if (!demo.isDemoMode) {
      dispatch(getTopicsProgressionByMonth());
      dispatch(getEngagement());
      dispatch(getTasks());
    } else {
      dispatch(getDemo());
    }
  }, [dispatch, demo.isDemoMode])

  return (
    <main className="Dashboard">
      <Summary selectedAbility={Number(selectedAbility)} setSelectedAbility={setSelectedAbility} />
      <AbilitiesAnalysis selectedAbility={selectedAbility} setSelectedAbility={setSelectedAbility} />
      <Loading visible={demo.isFetching || topics.isFetching || trends.isFetching || tasks.isFetching} />
    </main>
  );
};

export default Dashboard;
